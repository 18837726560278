import React, { useEffect, useState, useMemo } from 'react';
import HeaderNav from '../HeaderNav';
import { LdsButton, LdsIcon, LdsLink, LdsLoadingSpinner, LdsPagination, LdsTable, LdsTextField, LdsTile, LdsModal, useLdsModal, LdsDatepicker } from '@elilillyco/ux-lds-react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCapData, fetchCapDataforModal, capDataSlice } from '../../store/Components/OMMS/capDataDetails';
import { debounce } from 'lodash'; // Ensure lodash is installed

const CapOverview = () => {
    const dispatch = useDispatch();
    const [capData, setCapData] = useState([]);
    const [openFields, setOpenFields] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const { isModalOpen, setIsModalOpen } = useLdsModal();
    const [dataForModal, setDataForModal] = useState([]);
    const [dataFromAudit, setDataFromAudit] = useState({});
    const [totalPage, setTotalPage] = useState();
    const loading = useSelector(({ capDataSlice }) => capDataSlice.loader);
    const apiPayload = useMemo(() => ({
        delivery_source: inputValues['Delivery Source'] || "",
        page: currentPage,
        platform: inputValues['Platform'] || "",
        tactic_start_date: inputValues['Tactic Start Date'] || "",
        verso_campaign_id: inputValues['Verso Campaign Id'] || "",
        verso_tactic_id: inputValues['Verso Tactic Id'] || "",
    }), [inputValues, currentPage]);

    const fetchData = () => {
        dispatch(fetchCapData(apiPayload)).then((d) => {
            const data = d?.payload?.data || [];
            setCapData(data);
            setTotalPage(d?.payload?.numberOfPages)
            setFilteredData(data);
        });
    };

    const debouncedFetchData = debounce(fetchData, 300);

    useEffect(() => {
        debouncedFetchData();
        return debouncedFetchData.cancel; // Cleanup on unmount
    }, [apiPayload]);

    const handleHeaderClick = (index) => {
        setOpenFields(prev => prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]);
    };

    const handleInputChange = (e, key) => {
        const newValue = e.target.value;
        setInputValues(prev => ({ ...prev, [key]: newValue }));
    };

    const handleSearch = () => {
        setCurrentPage(1); // Reset to first page on search
        fetchData();
    };

    const handleClear = () => {
        setOpenFields([]);
        setInputValues({});
        setCurrentPage(1); // Reset to first page
        fetchData();
    };

    const [auditpayload, setAuditPayload] = useState()
    const [selectedDate, setSelectedDate] = useState();
    const handleClick = (item) => {
        setAuditPayload(item);
        const payloadForModal = {
            id: item?.verso_tactic_id,
            defaultFilter: 7,
        };
        setDataForModal(item);
        setIsModalOpen(true);
        dispatch(fetchCapDataforModal(payloadForModal)).then((d) => setDataFromAudit(d?.payload?.data || []));
    };

    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = new Date(year, month - 1, day);
    function getFutureDates(startDate, days) {
        const futureDates = [];
        for (let i = 0; i < days; i++) {
            const futureDate = new Date(startDate);
            futureDate.setDate(startDate.getDate() + i);
            futureDates.push(futureDate.toISOString().split('T')[0]);
        }
        return futureDates;
    }

    currentDate.setDate(currentDate.getDate());
    const numberOfDays = 100;
    const disabledDatesFinal = getFutureDates(currentDate, numberOfDays);



    useEffect(() => {
        if (selectedDate && auditpayload) {
            const [selectedDay, selectedMonth, selectedYear] = selectedDate.split('/').map(Number);
            const selectedDateObject = new Date(selectedYear, selectedMonth - 1, selectedDay);
            const differenceInMilliseconds = selectedDateObject - currentDate;
            const differenceInDays = Math.abs(differenceInMilliseconds / (1000 * 60 * 60 * 24));

            const payloadForModal = {
                id: auditpayload?.verso_tactic_id,
                defaultFilter: Math.round(differenceInDays),
                pageSize: 10,
                pageNumber: 1,
            };

            setDataForModal(auditpayload);
            setIsModalOpen(true);
            dispatch(fetchCapDataforModal(payloadForModal)).then((d) => setDataFromAudit(d?.payload?.data || []));
        }
    }, [selectedDate, auditpayload, dispatch]);


    return (
        <>
            <HeaderNav msg={"CAP DATA DETAILS"} />
            <div className="ms-3 mt-2 p-2"></div>
            {/* <div className="ms-3 mt-2 p-2">
                <LdsLink href="/tacct">
                    <LdsIcon description="CaretLeft" label="search" name="CaretLeft" inline />
                    Back to Overview
                </LdsLink>
            </div> */}
            <span className="overview-text container ">Overview</span>
            <div className='container'>
                <LdsButton onClick={handleSearch}>Search</LdsButton>
                &nbsp;
                <LdsButton onClick={handleClear}>Clear</LdsButton>
            </div>
            <LdsTile>
                <LdsTable>
                    <thead>
                        <tr>
                            {['Verso Tactic Id', 'Verso Campaign Id', 'Delivery Source', 'Platform', 'Tactic Start Date'].map((header, index) => (
                                <th key={index} className='ta-center' style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleHeaderClick(index)}>
                                    {header} &nbsp;
                                    <LdsIcon name="MagnifyingGlass" inline />
                                    {openFields.includes(index) && (
                                        <><LdsTextField
                                            id={header.replace(/\s+/g, '').toLowerCase()}
                                            value={inputValues[header] || ''}
                                            onChange={(e) => handleInputChange(e, header)}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                        </>
                                    )}
                                </th>
                            ))}
                            <th className='ta-center' style={{ fontWeight: 'bold' }}>Tactic End Date</th>
                            <th className='ta-center' style={{ fontWeight: 'bold' }}>Product</th>
                            <th className='ta-start' style={{ fontWeight: 'bold' }}>Disease State Indication</th>
                            <th className='ta-start' style={{ fontWeight: 'bold' }}>Department</th>
                            <th style={{ fontWeight: 'bold' }}>Audit Logs</th>
                        </tr>
                    </thead>
                    {filteredData?.length === 0 ? <LdsLoadingSpinner animationSpeed={1000} ariaLabel="loading" id="demo-loading-spinner" size={40} svgTitle="loading" className=' p-3 ' /> :
                        <tbody>
                            {filteredData.map((item, index) => (
                                <tr key={index} role="row">
                                    <td className='ta-center'>{item.verso_tactic_id}</td>
                                    <td className='ta-center'>{item.verso_campaign_id}</td>
                                    <td className='ta-center'>{item.delivery_source}</td>
                                    <td className='ta-center'>{item.platform}</td>
                                    <td className='ta-center'>{item.tactic_start_date}</td>
                                    <td className='ta-center'>{item.tactic_end_date}</td>
                                    <td className='ta-center'>{item.product}</td>
                                    <td className='ta-start'>{item.disease_state_indication}</td>
                                    <td className='ta-start'>{item.department}</td>
                                    <td>
                                        <LdsIcon onClick={() => handleClick(item)} name="InfoFill" style={{ maxWidth: '30px', maxHeight: '30px', float: "right" }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>}
                </LdsTable>
                <LdsPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    onNextClick={() => setCurrentPage(prev => prev + 1)}
                    onPrevClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    useButtons={true}
                    totalPages={totalPage}

                />
            </LdsTile>
            <LdsModal
                modalId="testModal2"
                open={isModalOpen}
                setModalOpen={setIsModalOpen}
                persistent
            >
                <h3 className='container overview-text'>Audit Table</h3>
                <LdsTable>
                    <thead>
                        <tr>
                            {['Verso Tactic Id', 'Verso Campaign Id', 'Delivery Source', 'Platform', 'Tactic Start Date', 'Tactic End Date', 'Product', 'Disease State Indication', 'Department'].map((key, index) => (
                                <th className='ta-center' style={{ fontWeight: 'bold' }} key={index}>{key}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataForModal ? (
                            <tr role="row">
                                <td className='ta-center'>{dataForModal.verso_tactic_id}</td>
                                <td className='ta-center'>{dataForModal.verso_campaign_id}</td>
                                <td className='ta-center'>{dataForModal.delivery_source}</td>
                                <td className='ta-center'>{dataForModal.platform}</td>
                                <td className='ta-center'>{dataForModal.tactic_start_date}</td>
                                <td className='ta-center'>{dataForModal.tactic_end_date}</td>
                                <td className='ta-center'>{dataForModal.product}</td>
                                <td className='ta-start'>{dataForModal.disease_state_indication}</td>
                                <td className='ta-start'>{dataForModal.department}</td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan="9" className="ta-center">No data available</td>
                            </tr>
                        )}
                    </tbody>
                </LdsTable>
                <h2 className='container mt-2'>Audit History</h2>
                <LdsDatepicker
                    label="Select Date to view Data from selected date to the current Date"
                    dateFormat='DD/MM/YYYY'
                    onChange={(e) => setSelectedDate(e)}
                    disabledDates={
                        disabledDatesFinal
                    }
                />
                <div style={{ maxHeight: "400px", overflowY: "auto", }}>
                    <LdsTable>
                        <thead>
                            <tr>
                                {['Verso Tactic Id', 'Verso Campaign Id', 'Delivery Source', 'Platform', 'Tactic Start Date', 'Tactic End Date', 'Product', 'Disease State Indication', 'Department', 'Update Date'].map((key, index) => (
                                    <th className='ta-center' style={{ fontWeight: 'bold' }} key={index}>{key}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>

                            {loading ? (
                                <tr>
                                    <LdsLoadingSpinner animationSpeed={1000} ariaLabel="loading" id="demo-loading-spinner" size={40} svgTitle="loading" className='p-3' />
                                </tr>
                            ) : dataFromAudit.length > 0 ? (
                                [...dataFromAudit].map((auditItem, index, reversedData) => {
                                    const nextRow = index < reversedData.length - 1 ? reversedData[index + 1] : null;
                                    const highlightIfDifferent = (field) => {
                                        return nextRow && nextRow[field] !== auditItem[field]
                                            ? 'highlight-text' // Apply highlight class if there's a difference
                                            : '';
                                    };

                                    return (
                                        <tr key={index} role="row">
                                            <td className="ta-center">
                                                <span className={highlightIfDifferent('verso_tactic_id')}>
                                                    {auditItem.verso_tactic_id}
                                                </span>
                                            </td>
                                            <td className="ta-center">
                                                <span className={highlightIfDifferent('verso_campaign_id')}>
                                                    {auditItem.verso_campaign_id}
                                                </span>
                                            </td>
                                            <td className="ta-center">
                                                <span className={highlightIfDifferent('delivery_source')}>
                                                    {auditItem.delivery_source}
                                                </span>
                                            </td>
                                            <td className="ta-center">
                                                <span className={highlightIfDifferent('platform')}>
                                                    {auditItem.platform}
                                                </span>
                                            </td>
                                            <td className="ta-center">
                                                <span className={highlightIfDifferent('tactic_start_date')}>
                                                    {auditItem.tactic_start_date}
                                                </span>
                                            </td>
                                            <td className="ta-center">
                                                <span className={highlightIfDifferent('tactic_end_date')}>
                                                    {auditItem.tactic_end_date}
                                                </span>
                                            </td>
                                            <td className="ta-center">
                                                <span className={highlightIfDifferent('product')}>
                                                    {auditItem.product}
                                                </span>
                                            </td>
                                            <td className="ta-start">
                                                <span className={highlightIfDifferent('disease_state_indication')}>
                                                    {auditItem.disease_state_indication}
                                                </span>
                                            </td>
                                            <td className="ta-start">
                                                <span className={highlightIfDifferent('department')}>
                                                    {auditItem.department}
                                                </span>
                                            </td>
                                            <td className="ta-center">
                                                {new Date(auditItem.updated_date).toLocaleString()}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="10" className="ta-center">No data found</td>
                                </tr>
                            )}

                        </tbody>

                    </LdsTable>
                </div>
                <LdsButton className="lds-button mt-2 p-2" onClick={() => (setIsModalOpen(false))}>Close</LdsButton>
            </LdsModal>
        </>
    );
};

export default React.memo(CapOverview);
