/**
 * Post file to validate.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */

import ctx from "../../../plugins/axios"
/* eslint-disable import/no-anonymous-default-export */
export default async(payload, signal) => {
    const { data, status } = await ctx.post("/adhocfilevalidate", {file: payload, headerslist: ['EPH_CUST_REFERENCE_ID' , 'SEGMENT']}, { signal,});
    return { data, status };
}